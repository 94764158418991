.home .label i {
  margin: 0px 10px !important;
}

.content {
  width: 70%;
  margin-right: 50px !important;

}

.content h2 {
  font-size: 50px;
}

.header-img {
  z-index: -10;
}

.home-input-translate {
  transform: translateX(-50px);
}

.home-videos-sec .slick-prev:before,
.slick-next:before {
  font-size: 50px !important;
  color: var(--mainColor) !important;

}

.home-videos-sec .slick-prev:hover,
.slick-next:hover {
  background-color: transparent !important;
  font-size: 50px !important;
}

.home-videos-sec .slide {
  text-align: center;
  background-color: transparent !important;
  border-radius: 5px;
  font-weight: 500;
  border: 0px;
}

.home-bottom-slider .slick-prev {
  display: none !important;
}

.home-videos-sec .home-videos-sec.social-ic i {
  color: var(--mainColor) !important;
}

/* showing the left slider button (up)  */
.home-slider .slick-prev {
  left: -3% !important;
}

/* movingt on next btn   */
.home-videos-sec .slick-next {
  right: -3% !important;
  z-index: 2 !important;
}

.content-h2 {
  font-size: 50px !important;
}

.home-videos-sec .home-videos-sec.slick-prev:hover,
.slick-next:hover {
  background-color: transparent !important;
}

.home-static-num {
  position: absolute !important;
  bottom: 41% !important;
  left: 50% !important;
}

.home-static-num h4 {
  font-weight: bold !important;
  font-size: 40px !important;
}

.home-bottom-slider h2 {
  font-size: 55px !important;
}

.opinier-img img {
  width: 40px;
  height: 40px;
}

/* /////////////////// */
.small-article img {
  width: 100%;
}

.big-article img {
  width: 100%;
  max-height: 350px !important;
}

.article-post img {
  width: 100% !important;
  height: 320px;
  object-fit: cover;
}

.home-opinier-img img {
  width: 60px !important;
  height: 60px !important;
}

.starts i {
  font-size: 20px;
}

.home-blog-img {
  max-height: 200px;
  object-fit: cover;
}

.customers-opinion-slider .slick-next,
.customers-opinion-slider .slick-slider button,
.customers-opinion-sliderr .slick-prev {
  display: none !important;
}

.home-article-link {
  position: absolute !important;
  top: 30%;
  left: 15%;

}

.margin-150 {
  margin-right: 150px !important;
}

/* CSS */
.home-blog-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.home-blog-card img {
  flex-shrink: 0;
  /* Ensure the image doesn't shrink */
}

.home-blog-card span,
.home-blog-card h3,
.home-blog-card p {
  flex-grow: 1;
  /* Make these elements grow to fill available space */
}

.home-blog-card p {
  margin-top: auto;
  /* Push the paragraph to the bottom of the card */
}

/*  */
.borderBottom {
  position: relative;
  /* This allows positioning of the ::after element */
  display: inline-block !important;
}

.fw-600 {
  font-weight: 600 !important;
}

article .font-56 {
  font-size: 56px !important;
}

.borderBottom::after {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--mainColor);
  position: absolute;
  bottom: 50%;
  right: -7%;
}

.borderBottom {
  padding-bottom: 10px;
  border-bottom: 1px solid var(--mainColor);

}

.home-header-img {
  width: 90%;
  padding: 10px 10px 0px;
  transform: translate(100px)
}

.padding-right-100 {
  padding-Right: 100px !important
}

.num1 {
  position: absolute;
  top: 30%;
  left: 10%;
  z-index: 7;
  width: 140px;
}
.doctor-img {
  width: 170px !important;
  height: 170px !important;
}
@media screen and (max-width:1000px) {
  .doctor-img {
      width: 100% !important;
      height: 100% !important;
  }
}
/* MEDIA SCREEN */
@media (max-width: 1400px) {

  .home-slider .slick-prev {
    display: none !important;
  }

  /* Hide next button */
  .home-videos-sec .slick-next {
    display: none !important;
    z-index: 2 !important;
  }

  .home-header-img {
    width: 100%;
    padding: 0px;
    transform: translate(0px)
  }

  .padding-right-100 {
    padding-Right: 0px !important
  }
  .home-input-translate {
    transform: translateX(0px);
  }
}

@media screen and (max-width:995px) and (min-width:767px) {

  .home-statistic {
    display: none;
  }

  .content {
    width: 100%;
    margin: 0px !important;
  }

  .home form {
    transform: translate(0);
  }

  .home {
    padding: 20px;
  }

  .why-us-img {
    width: 70% !important;
  }

  .team-header p {
    padding: 0px 20px !important;
    text-align: center !important;
  }

  .margin-150 {
    margin-right: 150px !important;
  }

  .home-header-img {
    width: 100%;
    padding: 0px;
    transform: translate(0px)
  }

  .home-bottom-slider h2 {
    font-size: 40px !important;
  }

  .home-blog-card {
    padding: 30px;
  }

  .header-img {
    border-radius: 20px !important;
    margin-top: 20px !important;
  }

  .who-are-we,
  .why-us {
    padding: 20px;
  }
}

@media screen and (max-width:1400px) {
  .col-100{
    width: 100% !important;
  }
 .header-img{
  display: none;
 }
}
@media screen and (max-width:995px) {

  .content {
    width: 100%;
    margin: 0px !important;
  }

  .home form {
    transform: translate(0);
  }

  .flip-horizontal {
    transform: scaleX(-1);
  }
  

  .header-img {
    /* border-radius: 20px !important; */
    /* margin-top: 20px !important; */
    display: none;
  }

  .home-header-img {
    width: 100%;
    padding: 0px;
    transform: translate(0px)
  }

  .padding-right-100 {
    padding-Right: 0px !important
  }
}

@media (max-width: 900px) {
  .home-videos-sec .slide {
    padding: 15px;
    /* Reduced padding on smaller screens */
    overflow: hidden;
    /* Hide any overflowing content */
  }

  .home-bottom-slider h2 {
    font-size: 35px !important;
    /* Adjust heading size */
  }

  .opinier-img img {
    width: 30px;
    height: 30px;
  }

  .home-slider .slick-prev {
    display: none !important;
  }

  /* Hide next button */
  .home-videos-sec .slick-next {
    display: none !important;
    z-index: 2 !important;
  }

  .home form {
    transform: translateX(0px) !important;
  }

  .home-statistic {
    position: absolute !important;
    top: 30% !important;
    left: 40% !important;
    transform: translate(0) !important;
  }

  .home-static-num {
    position: absolute !important;
    top: 20% !important;
    left: 50% !important;
  }

  .home-header-img {
    width: 100%;
    padding: 0px;
    transform: translate(0px)
  }

  .padding-right-100 {
    padding-Right: 0px !important
  }
}

@media (max-width: 768px) {
  .home-videos-sec .slide {
    padding: 15px;
    /* Reduced padding on smaller screens */
    overflow: hidden;
    /* Hide any overflowing content */
  }

  .home-bottom-slider h2 {
    font-size: 35px !important;
    /* Adjust heading size */
  }

  .opinier-img img {
    width: 30px;
    height: 30px;
  }

  .home-slider .slick-prev {
    display: none !important;
  }

  /* Hide next button */
  .home-videos-sec .slick-next {
    display: none !important;
    z-index: 2 !important;
  }

  .home-statistic {
    position: absolute !important;
    top: 20% !important;
    left: 30% !important;
    transform: translate(0) !important;
  }

  .home-static-num {
    position: absolute !important;
    top: 20% !important;
    left: 50% !important;
  }

  .home form {
    transform: translateX(0px) !important;
  }

  .home-header-img {
    width: 100%;
    padding: 0px;
    transform: translate(0px)
  }

  .padding-right-100 {
    padding-Right: 0px !important
  }
}

@media screen and (max-width: 576px) {
  .contain {
    /* width: 100% !important; */
    margin: auto;
    overflow: hidden;
    /* Hide any overflowing content */
  }

  .home .content {
    padding: 10px;
  }

  .home-videos-sec {
    padding: 10px;
  }

  .home-videos-sec .slide {
    padding: 15px !important;
    /* Reduced padding on smaller screens */
    overflow: hidden;
    /* Hide any overflowing content */
  }

  .home-bottom-slider h2 {
    font-size: 30px !important;
    /* Adjust heading size */
  }

  .opinier-img img {
    width: 30px;
    height: 30px;
  }

  .home-slider .slick-prev {
    display: none !important;
  }

  /* Hide next button */
  .home-videos-sec .slick-next {
    display: none !important;
    z-index: 2 !important;
  }

  .home-statistic {
    display: none !important;
  }

  .home-static-num {
    position: absolute !important;
    top: 20% !important;
    left: 45% !important;
    font-size: 10px !important;
  }

  .home form {
    transform: translateX(0px) !important;
  }

  .home-header-img {
    width: 100%;
    padding: 0px;
    transform: translate(0px)
  }

  .padding-right-100 {
    padding-Right: 0px !important
  }
}
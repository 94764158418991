@import '../../Variables.css';
/* Card.css */

 .service-card  {
    background-color: #E3FEFA !important;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    margin: 10px;
    flex: 1;
  }
  
  .service-card .icon {
    margin-bottom: 10px;
    width: 50px ;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 50px ;
  }
  .service-card .icon i{
    font-size: 30px;
    color: var(--mainColor);
  }
  
  .service-card .content h3 {
    color: #333;
    font-size: 1.5em;
    margin: 10px 0;
  }
  
  .service-card .content p {
    color: #666;
    font-size: 1em;
    margin: 10px 0;
  }
  
  .service-card .button {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #b3e5e5;
    color: #00796b;
    border-radius: 50px;
    text-decoration: none;
    transition: all 0.3s;
  }
  
  .service-card  .button:hover {
    background-color: #00796b;
    color: #fff;
  }
  
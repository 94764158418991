
  .posts-container {
    display: flex;
    flex-wrap: wrap; 
    justify-content: center; 
  }
  
  .flex-item {
    flex-grow: 1;
    flex-basis: 300px; 
    margin: 10px; 
    box-sizing: border-box; 
  }
  
  .post {
    transition: transform 0.2s;
    border: 2px solid var(--mainColor); 

  }
  

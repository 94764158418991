@import '../../Component/Variables.css';
.contact-head h3{
color: var(--mainColor);
}
.contact-head p{
color: var(--ParagrafColor) !important;
}
.contact-page {
  padding: 2rem 0;
}

.contact-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-info, .contact-form {
  flex: 1;
  min-width: 300px;
  margin: 10px;
}
.info-box {
    background-color: #fff;
    padding: 2rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    text-align: right;
    direction: rtl;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.103); /* Adding a subtle shadow */

  }
  
  .info-box .icon {
    background-color: var(--mainColor);
    color: #fff;
    border-radius: 50%;
    padding: 10px;
    font-size: 1.2rem;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem; /* Space between icon and text */
  }
  
  .info-box h6 {
    margin: 0;
    padding: 0;
  }

.info-box p {
  margin: 0;
  padding-right: 2rem;
}


.info-box .icon i {
  margin: 0;
}

form {
  padding: 0rem;
  border-radius: 8px;
  text-align: right;
  direction: rtl;
}

.form-row {
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex ;
  gap: 40px;
  justify-content: space-between !important;

}

.form-group {
  flex: 1;
  min-width: 45%;
  margin: .5rem 1rem;
  margin: 0 !important;

  
}

form label {
  display: block;
  margin-bottom: 0.5rem;
  color: var(--mainColor);
}

form input, form textarea, form select {
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
  text-align: right;
  direction: rtl;
  outline: none;
  border: 1px #18194521 solid;
}
/* custom */
form select {
    padding: 1rem 2rem 1rem 1rem !important;
    cursor: pointer;

}
/* bootstrap */
.form-select{
box-shadow: none !important;
border: none !important;
}
.contact-container button[type="submit"] {
  width: 100%;
  padding: 0.75rem;
  background-color: var(--mainColor);
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.contact-container button[type="submit"]:hover {
  background-color: darken(var(--mainColor), 10%);
}
.branch-card-column{
  transition: all .5s;
}
.branch-card-column:hover{
transform: scale(1.05);
cursor: pointer;

}


@media screen and (max-width:900px) {

 .form-row{
  display: block !important;
 }

}

@media screen and (max-width:700px) {

 .form-row{
  display: block !important;
 }
 .info-box .row{
  display: flex;
  flex-direction: column;
 }
 .branch-card-column {
  padding: 30px  !important;
 }
 .info-box .div-center{
  text-align: center !important;
}

}

@media screen and (max-width:576px) {

 .form-row{
  display: block !important;
 }
 .branch-card-column {
  padding: 30px !important;
 }
.info-box .div-center{
  text-align: center !important;
}
.font-sm-h2{
  font-size: 36px !important;
}
}
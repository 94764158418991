.service-details {
    padding: 40px 20px;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .service-details-content {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .service-title {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .service-description {
    font-size: 1rem;
    line-height: 1.6;
    color: #666;
  }
  
.reservation-details-section {
    padding: 20px;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .text-mainColor {
    color: #007bff; /* Adjust to your main color */
  }
  
  .reservation-info p {
    font-size: 1.2rem;
    color: #333;
  }
  
  .info-card {
    padding: 20px;
  }
  
  .image-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  
  .reservation-image {
    max-width: 80px;
    border-radius: 5px;
    border: 1px solid #ddd;
    padding: 5px;
    background-color: #fff;
  }
  
  @media (max-width: 768px) {
    .reservation-info p {
      font-size: 1rem;
    }
  
    .reservation-image {
      max-width: 70px;
    }
  }
  
@import '../Variables.css';
  
  h1, a{
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  
  .section{
    padding: 4rem 2rem;
  }
  
  .section .error{
    font-size: 150px;
    color: var(--mainColor);
    text-shadow: 
      1px 1px 1px #00593E,    
      2px 2px 1px #00593E,
      3px 3px 1px #00593E,
      4px 4px 1px #00593E,
      5px 5px 1px #00593E,
      6px 6px 1px #00593E,
      7px 7px 1px #00593E,
      8px 8px 1px #00593E,
      25px 25px 8px rgba(0,0,0, 0.2);
  }
  
  .page{
    font-size: 20px;
    font-weight: 600;
    color: #444;
  }
  
  .back-home{
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.75rem 1rem 0.6rem;
    transition: all 0.2s linear;
    box-shadow: 0 15px 15px -11px rgba(0,0,0, 0.4);
    background: #222;
    border-radius: 6px;
    margin: 20px 0px;
  }
  .back-home:hover{
    background:#ff8e0c;
    color: #ddd;
  }
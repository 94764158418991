@import '../Variables.css';
.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 40px;
  height: 40px;
  background-color: var(--mainColor);
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  border: 1px solid white;
  visibility: hidden;
  transition: all  0.4s;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.scroll-to-top:hover {
  background-color: var(--mainColorb);
}

.scroll-to-top:hover .fa-arrow-up {
  transform: scale(1.2);
}

.scroll-to-top .fa-arrow-up {
  transition: transform 0.4s;
}

/* Importing Variables.css for theme colors and other variables */
@import '../Variables.css';

/* Services Gallery Section */
.services-gallary {
    width: 90%; /* Set width of the services gallery section */
}

/* Marquee Styling */
marquee {
    background-color: var(--smallColor) !important; /* Set background color for marquee */
}

/* Text color for white text in the services section */
.services .text-white {
    color: white; /* Set text color to white */
    display: inline-block; /* Ensure text is only as wide as its content */
    margin-right: 20px; /* Space between text items */
}

/* Smile Section Styling */
.smile-section-body {
    background-color: var(--mainColorC); /* Set background color */
    max-height: 700px; /* Set maximum height */
}

/* Contact Section Button Styling */
.contact-section button[type="submit"] {
    width: 100%; /* Full width button */
    padding: 0.75rem; /* Padding inside the button */
    background-color: var(--mainColor); /* Background color */
    color: #fff; /* Text color */
    border: none; /* Remove default border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    font-size: 1rem; /* Font size */
}

/* Contact Section Button Hover Effect */
.contact-section button[type="submit"]:hover {
    background-color: darken(var(--mainColor), 10%); /* Darken background color on hover */
}

/* Form Row Styling */
.form-row {
    display: flex; /* Use flexbox for layout */
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    gap: 40px; /* Space between items */
    justify-content: space-between; /* Space items evenly */
}

/* Underline Styling for Section Headings */
.underline {
    border-bottom: 2px solid var(--mainColor); /* Underline color and thickness */
    display: inline-block; /* Underline only as wide as the text */
    padding-bottom: 10px; /* Space between text and underline */
    color: var(--mainColor); /* Text color */
}

/* Button Styling in Services Section */
.services .btn {
    border: 2px solid var(--mainColor) !important; /* Button border color */
    color: var(--mainColor) !important; /* Text color */
}

/* Hover Effect for 'More Videos' Link */
.services .more-videos-a {
    transition: all .5s; /* Smooth transition effect */
}

.services .more-videos-a:hover {
    transform: translateX(-10px) !important; /* Shift link to the left on hover */
}

/* Button Hover Effect in Services Section */
.services .btn:hover {
    background-color: var(--mainColorC) !important; /* Background color on hover */
    color: white !important; /* Text color on hover */
}

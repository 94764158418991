@import '../Variables.css';

.button-container {
  display: inline-block; /* Ensures the container is only as wide as the button */
}

.button {
  display: inline-block; /* Ensures the button is only as wide as its content */
  padding: 0.5rem 2rem; /* Adjust padding as needed */
  border: var(--mainColor) 1px solid;
  border-radius: 2px; /* Adjust border-radius as needed */
  color: var(--mainColor); /* Use your main color for text */
  text-decoration: none; /* Remove underline from link */
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effects */
}

.button:hover {
  background-color: var(--mainColor); /* Background color on hover */
  color: white; /* Text color on hover */
}

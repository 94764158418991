@import '../Variables.css';
.footer a{
    color: white !important;
}
.footer h5{
    padding: 8px 0px;
}
.footer-welcome-p{
line-height: 26px;
margin: 340px !important;
}

/* FOOTER SEARCH BAR */
.search-container {
    position: relative;
    width: 300px;
}

.footer input[type="text"] {
    width: 100% !important;
    padding: 10px 10px 10px 100px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    outline: none !important;
    box-sizing: border-box !important;
}
.search-container input[type="text"]::placeholder {
    color: #aec6d1; 
}
.footer button {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border: none;
    padding: 0 30px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
}
.footer button:hover {
    background-color: #76b233;
}

/* FOOTER CONTACT UNDERLINE */
.contact-heading {
    position: relative;
    display: inline-block;
}

.contact-heading::after {
    content: "";
    display: block;
    width: 70px; /* Adjust the width of the line */
    height: 2px; /* Adjust the height of the line */
    background-color: #8dc63f; /* Change to your desired color */
    margin: 0.5rem 0 0; /* Adjust spacing */
}

.footer a:hover{
color: var(--smallColor) !important;
}

@media screen  and (max-width:1400px){
    
   .footer {
    text-align: center !important;
   }
   .footer .search-container{
    margin: auto !important;
   }
   .contact-heading::after {
    margin: 10px auto !important; /* Adjust spacing */
    
   }
 
}
@media screen  and (max-width:900px){
   .footer {
    text-align: center !important;

   }
   .footer .search-container{
    margin: auto !important;
   }
   .contact-heading::after {
    margin: 10px auto !important; /* Adjust spacing */
    
   }
}





@media screen  and (max-width:1300px) and (min-width:980px){

.footer .footer-col{
    width: 30% !important;
    margin: auto;
    margin-top: 0%;
}

}
@media screen  and (max-width:978px) and (min-width:600px){

.footer-col .footer-logo{
    width: 50% !important;
}
}








@media screen  and (max-width:600px){
   .footer {
    text-align: center;
    margin-left: 0;
    margin-right: 0;
    

   }
   .footer .search-container{
    margin: auto !important;
   }
   .contact-heading::after {
    margin: 10px auto !important; /* Adjust spacing */
    
   }
}

.whyus h4::before {
  content: "\f058";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  display: inline-block;
  font-size: 1rem;
  color: var(--mainColor);
  margin: 0px 18px;
}

.medical-adventage {
  position: relative;
  padding-top: 20px;
}

.medical-adventage::before {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 3px;
  height: 70%;
  background-color: var(--mainColor);
}

.what-we-offer .whyus h4::before {
  color: var(--smallColor);
}

.about ul li {
  padding: 0px 5px;
}

.about-statistic h2 {
  font-size: 4rem !important;
  color: var(--mainColor);
  font-weight: 900;
}

.what-we-offer img {
  transform: translateY(-20%);
  border-radius: 40px 0px 0px 40px;
}

.adventage h2 {
  font-size: 70px !important;
  font-weight: bold;
  color: var(--smallColor);
  opacity: .3;
}

.adventage p {
  font-size: 15px !important;
  color: var(--ParagrafColor) !important;
}
.about-experience {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
}

.about-experience .col-md-5 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 15px;
}

.about-experience .col-md-6 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 15px;
}
.big-text-h2{
  font-size: 50px !important;
}

.doctorCard {
  margin: 15px auto;
}

.opinion img {
  width: 100%;
  height: auto;
}

.social-ic ul {
  padding: 0;
  margin: 10px 0;
}

.social-ic ul li {
  list-style: none;
}

.social-ic ul li a {
  color: #000;
  font-size: 20px;
}

.social-ic ul li a:hover {
  color: #007bff;
}

.slider {
  margin: 0 auto;
  max-width: 100%;
}

.about-fImage {
/* width: 700px !important; */
height: 500px;
object-fit: cover;
}


@media (min-width: 768px) {
  .about-experience .col-md-5 {
    flex: 0 0 45%;
    max-width: 45%;
  }

  .about-experience .col-md-6 {
    flex: 0 0 55%;
    max-width: 55%;
  }
}
@media screen and (max-width:992px) {
  .what-we-offer img {
    transform: translate(0);
    border-radius: 0;
  }
}
@media screen and (max-width:1400px) and (min-width:600px) {
  .whyus{
    width: 50% !important;
  }
}

@media screen and (max-width:1400px) {
  .what-we-offer img {
    transform: translate(0);
    border-radius: 0;
    height: 100%;
    object-fit: contain;
  }

}


@media (max-width: 576px) {
  .about-experience {
    flex-direction: column;
  }
  .what-we-offer h2{
    font-size: large !important;
  }
  .about-experience .col-md-5,
  .about-experience .col-md-6 {
    padding: 10px;
  }

  .home-slider h3 {
    font-size: 20px;
  }

  .home-slider h4 {
    font-size: 18px;
  }
  .about-experience h2{
font-size: 35px !important;
}
.about-experience .slick-next,.about-experience .slick-prev{
display: none !important;
}
}

@media (min-width: 768px) and (max-width: 992px) {
  .about-experience .col-md-5 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .about-experience .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .opinion img {
    width: 100%;
  }

}

@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;500;600;700;800&display=swap');

:root{
  --mainColor:#02A19A;
  --mainColorb:#1EB8A4;
  --mainColorC:#02a19938;
  --mainColorD:#1eb8a415;
  --backColor:#edebeb;
  --smallColor:#95C11F;
  --ParagrafColor:#838282;
  --h1:40px;
  --h2:30px;
  --h3:24px;
  --h4:20px;
  --p:18px;
  --h5:14px;
  --h6:14px;
}
/* PostDetails.css */
@import'../../Variables.css';
.post-details .contain {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .post-details h1 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .post-details h2 {
    font-size: 1.5rem;
    color: var(--mainColor);
    margin-bottom: 20px;
    text-align: center;

  }
  
  .post-details img {
    display: block;
    margin: 0 auto 20px auto;
    border-radius: 10px;
  }
  
  .post-details .object-fit-contain {
    width: 100%;
    height: auto;
    max-width: 100%;
  }
  
  .post-details p {
    font-size: 1rem;
    color: #777;
    text-align: right;
    margin-top: 20px;
    padding: 0px 20px;
    line-height: 1.8;
  }
  
  .post-details section {
    padding: 40px 0;
    background-color: #f9f9f9;
  }
  
  @media (max-width: 768px) {
    .post-details   .contain {
      padding: 15px;
    }
  
    .post-details    h1 {
      font-size: 2rem;
    }
  
    .post-details h2 {
      font-size: 1.25rem;

    }
  
    .post-details  p {
      font-size: 0.875rem;
    }
  }

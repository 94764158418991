.categories {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .category {
    flex: 1 1 200px;
    padding: 15px;
    background-color: #f5f5f5;
    border-radius: 10px;
    text-align: center;
    transition: background-color 0.3s ease;
    text-decoration: none;
  }
  
  .category:hover {
    background-color: #e0e0e0;
  }
  
  .category h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
  }
  
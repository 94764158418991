
/* Add a class to ensure uniform input styling */

  /* Styling for select and phone input components */
  .reserve input[type="text"],
  .reserve select,
  .reserve .DatePicker__input,
  .reserve .PhoneInputInput {
    width: 100%;
    height: 45px;        /* Set a consistent height */
  }
  
  /* Ensure DatePicker has consistent height */
  .reserve .react-datepicker-wrapper,
  .reserve .react-datepicker__input-container {
    display: block;
    width: 100%;
  }
  
  .reserve .react-datepicker__input-container input {
    width: 100%;
    height: 45px;        /* Ensure same height as other inputs */
  }
  
  /* Button styling */
  .reserve  button[type="submit"] {
    height: 45px;        /* Ensure the button has the same height */
    font-size: 16px;
    background-color: #0066cc; /* Change button background color as needed */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .reserve button[type="submit"]:disabled {
    background-color: #ccc;
  }
  .reserve .success-popover{
    background-color: rgba(0, 0, 0, 0.342);
}
.PhoneInput{
    padding: 0px !important;
    display: flex !important;
}
.PhoneInputInput{
    padding: 0px !important;
    display: flex !important;
    border: 0px;
}
#countryCode{
    width: fit-content;
}
.PhoneInputInput{
width: 40px;
height: 100%;
}
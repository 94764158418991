/* Sidebar.css */
.sidebar {
    background-color: #f8f9fa;
    padding: 15px;
    border-right: 1px solid #ddd;
    height: 100%;
  }
  
  .sidebar h4 {
    margin-bottom: 20px;
  }
  
  .list-group-item {
    border: none;
  }
  
  .nav-link {
    text-decoration: none;
    color: #007bff;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }
  

  /* update imgae */
  .profile-image-hover {
    transition: transform 0.3s ease, opacity 0.3s ease; /* Smooth transition effect */
}

.profile-image-hover:hover {
    transform: scale(1.02); /* Enlarge the image slightly */
    opacity: 0.9; /* Slightly reduce opacity on hover */
}
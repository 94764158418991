.nav-item{
    font-weight: 600 !important;
    color: black;
}
.nav-btn:hover,button:hover{
background-color:#08b4ac !important;
}
.profile-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0px 10px;
}
.profile-img:hover{
    opacity: .7;
}




@media screen  and (max-width:1400px){
    
    .footer {
     text-align: center !important;
    }
    .footer .search-container{
     margin: auto !important;
    }
   
}
 @media screen  and (max-width:768px){
    .footer {
     text-align: center !important;
 
    }
    .footer .search-container{
     margin: auto !important;
    }
 }
 @media screen  and (max-width:576px){
   .footer {
     text-align: center;
     margin-left: 0;
     margin-right: 0;
    }
   .top-nav-column .center-section{
   display: none !important;
   }
      .top-nav-column{
      display: flex !important;
      flex-direction: column !important;
   }
 }
 

@import '../Variables.css';

.artcile-post p{
font-weight: 100 !important;
font-size: 18px !important;
color: #9D9B9B !important;
}
.statistic{
    background-color: #F1F8FF !important;
    text-align: center !important;
}
.statistic-ic{
    width: 40px;
    height: 40px;
    background-color: rgb(133, 211, 32) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius:8px ;
}

/* StatsSection.css */
.stats-section {
    border-radius: 10px;
  
}
  
  .stat-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }
  .stat-item i,.stat-item h3{
    font-size: 2rem !important;
    font-weight: 900;
  }
  .stat-item p{
    font-size: 1rem !important;
font-weight: 600;
}
  
  .icon-container {
    background-color: var(--mainColor); /* Adjust as needed */
    color: #fff;
    padding: 15px;
    border-radius: 8px;
    font-size: 2rem; /* Make the icon bigger */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 10px ;
  }
  
 .plus{
    font-size: 3rem;
 }
  
/* .statistic *{
  text-align: right ;
} */

  @media (max-width: 900px) {
    .stat-item{
      display: flex;
      justify-content: start !important;
    }
  }
  @media (max-width: 768px) {
   
    .icon-container {
      margin-bottom: 10px;
      margin-right: 0;
      margin-left: 1rem; 
    }
  
    .stat-item h3 {
      font-size: 1.5rem !important;
    }
  
    .stat-item p {
      font-size: 0.875rem !important;
    }
  
    .plus {
      font-size: 2.5rem;
    }
   
 
  }
  
 
  
.slide {
  padding: 20px;
  text-align: center;
  background-color: var(--mainColorD);
  border-radius: 5px;
  font-weight: 500;
  border: 1px solid rgba(128, 128, 128, 0.158);
}

.opinier-img {
  display: flex;
  justify-content: center;
  align-items: center;

}

.opinier-img img {
  width: 30px;
  height: 30px;
  border-radius: 50%;

}

.fa-star {
  color: rgb(255, 187, 0);
}

.slick-prev {
  left: 10% !important;
  /* Adjust left arrow position */
  z-index: 2 !important;
}


.slick-next {
  right: 10% !important;
  /* Adjust right arrow position */
  z-index: 2 !important;
}

/* Additional styling for the arrows */
.slick-prev:before,
.slick-next:before {
  font-size: 50px !important;
  color: var(--mainColor) !important;

}

.slick-prev:hover,
.slick-next:hover {
  background-color: transparent !important;
  font-size: 50px !important;
}

.about-experience .slide {
  padding: 10px;
  text-align: center;
  background-color: transparent !important;
  border-radius: 5px;
  font-weight: 500;
  border: 0px;
}


.about-experience .slick-prev {
  display: none !important;
}

.about-experience .social-ic i {
  color: var(--mainColor) !important;
}


.about-experience .slick-next {
  right: -5% !important;
  z-index: 2 !important;
}


.about-experience .slick-prev:hover,
.slick-next:hover {
  background-color: transparent !important;
}


@media screen and (min-width: 767px) and (max-width:992px){


  .text-slider-container .opinion {
    width: 100% !important;
  }
.about-experience h2{
  font-size: 36px !important;
}
.text-slider-container  .slick-next,.text-slider-container  .slick-prev{
  opacity: .4 ;
}
}
@media screen and (max-width: 576px) {


  .text-slider-container .opinion {
    width: 100% !important;
  }

  .text-slider-container  .slide {
    padding: 20px 0px !important;
    height: 100%;
  }
  .text-slider-container  .slick-next,.text-slider-container  .slick-prev{
    opacity: .4 ;
  }

}
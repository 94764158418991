.comparison-container {
  position: relative;
  max-width: 100%;
  max-height: 700px;
  margin: 20px auto;
  border-radius: 50px;
  overflow: hidden;
}

.comparison-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.custom-handle-button {
display: none;
}

.custom-handle-button i {
  /* font-size: 0px; */
  display: none;
}

.custom-handle {
display: none;
}


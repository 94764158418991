
@import '../src/Component/Variables.css';

body {
  font-family: 'Cairo', sans-serif !important;
  background-color: var(--backColor) !important;
}

.topNav,.footer,.navbar .btn{
  background-color: var(--mainColor) !important;
}
.reserve,.fa-calendar,.active,.article-headline,span,.article h6,.article a,.home .label i{
color: var(--mainColor) !important;
}
a{
  text-decoration: none !important;
}
.rtl {
  direction: rtl;
  text-align: right; /* Align text to the right, if needed */
}
ul{
list-style: none;
}
.bg-mainColor{
  background-color: var(--mainColor) !important;
}
.bg-mainColorLight{
  background-color: var(--mainColorC) !important;
}

.text-mainColor{
color: var(--mainColor) !important;
}
h1{
font-size: var(--h1) ;
line-height: 1.8 !important;
}
h2{
font-size: var(--h2) !important;
line-height: 1.5 !important;
}
h3{
font-size: var(--h3) !important;
line-height: 1.5 !important;

}
h4{
font-size: var(--h4) !important;
font-weight: 100 !important;  
line-height: 1.5 !important;

}
h5{
font-size: var(--h5) !important;
font-weight: 100 !important;  
line-height: 1.5 !important;

}
h6{
font-size: var(--h6) !important;
line-height: 1.5;

}
p{
font-size: var(--p) !important;
color: var(--ParagrafColor) !important;
line-height: 1.7 !important;
}
h1,h2,h3,h4,h5,h6,p{
  overflow-wrap: break-word; /* Ensures long words will break and wrap to the next line */
  word-break: break-word; /* Breaks long words and prevents overflow */
  text-overflow: ellipsis; /* Adds an ellipsis (...) if the text overflows */
  white-space: normal; /* Allows the text to wrap normally */
}
.contain{
  width: 80% !important;
  margin: auto !important;
}
.social-ic{
  font-size: 22px !important;
}
.top-nav .social-ic a,.footer .social-ic a{ 
  color: white !important;
}
.social-ic a{ 
  color: var(--mainColor) !important ;
}
span{
  font-weight: bold;
}


.list-style{
  list-style: none !important;
}

.btn:hover{
  background-color: var(--mainColorb) !important ;
  color: #FFF !important;
}




/* TEXT BACK */
.text::after{
  content: "TEXT";
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  color: blue;

}





.offline-div .offline{
  color: red !important;
}
.offline-div span{
  position:fixed;
  bottom: 10px;
  right: 3%;
  margin-top: 10px;
  color: rgb(255, 0, 0) !important;
  padding: 20px;
  border-radius: 10px;
}

/* HOVER TRANSPARENT */
.bg-hover-trans:hover{
  background-color: transparent !important;
  color: black !important;
}

/* HOVER MAINCOLOR */
.bg-hover-bgMain:hover{
  background-color: var(--mainColor) !important;
  color: rgb(255, 255, 255) !important;
}


.logo{
  max-height: 50px !important;
  object-fit: cover;
}
.back-text{
  /* background-color: aqua !important; */
  font-size: 140px;
  opacity: .1;
  transform: translateY(-100px) !important;
}

/* RESERVE POINTER */
.reserve-pointer:hover{
  cursor: pointer !important;
opacity: .7 !important;
}
.signup .contain .p-13{
  padding: 13px !important;
}
/* footer */
.footer button{
background-color: var(--smallColor);
}
/* ltr */
.ltr{
  direction: ltr !important;
  text-align: left !important;
}
.text-75 {
  width: 75%;
}
.home-customer-head{
  text-align: start !important;
}
.whyus h4{
  font-size: 18px !important;
  font-weight: 500;
}
@media (min-width: 1200px) {
  .h2, h2 {
      font-size: 2.5rem !important;
      font-weight: bolder;
  }
}
@media screen and (max-width: 1400px) {
.contain{
  width: 95% !important;
}
.text-75{
  width: 100%;
}
.home-customer-head{
  flex-direction: column;
  text-align: center !important;
}
.back-text{
  /* background-color: aqua !important; */
  font-size: 130px;
  transform: translateY(-90px) !important;
}

}
@media screen and (max-width: 700px) {
.contain{
  width: 95% !important;
}
.about-statistic{
  display: flex;
  margin-bottom: 50px;
 justify-content: space-between;
}
.back-text{
  /* background-color: aqua !important; */
  font-size: 100px;
  opacity: .1;
  transform: translateY(-50px) !important;
}
.num1{
  display: none;
}
.team-header p{
  width: 100%;
}

.what-we-offer .col-md-6{
  padding: 0px !important;
}
.what-we-offer img{
  transform: translate(0px) !important;
}
.home form{
  transform: translate(0px) !important;
}
.home-customer-head{
  flex-direction: column;
}
}
@media screen and (max-width: 576px) {
.contain{
  width: 95% !important;
}
.content h2 {
  font-size: 30px !important;
}
.about-statistic .num{
  font-size: 2rem !important;
}
p{
  padding: 3px !important;
}
.home-customer-head{
  flex-direction: column;
  padding: 10px;
}
.team-header p{
  width: 100%;
}
}

/* CSS to separate the country code visually from the phone number */
.react-phone-number-input input {
    width: calc(100% - 80px) !important; /* Adjust width of phone number input */
    display: inline-block !important;
    background-color: aqua !important;

  }
  
  .react-phone-number-input__icon {
    width: 60px !important; /* Adjust width of country flag and code */
    display: inline-block !important;
    background-color: aqua !important;
  }
  
  .react-phone-number-input__icon img {
    width: 40px !important;
    height: auto !important;
    display: inline-block !important;
    background-color: aqua !important;

    vertical-align: middle !important;
  }
  